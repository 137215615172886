import React from "react";

const Link = ({ rel, href, name, img, src, invert, imgProps }) => {
  return (
    <a rel={rel} href={href} className="App-item link">
      <img
        className={`link-img${invert === "true" ? " link-invert" : ""}`}
        src={img || (src && require(`../${src}`))}
        alt={name}
        {...imgProps}
      />
      <span>{name}</span>
    </a>
  );
};

export default Link;